<template>
  <div>
    <v-row>
      <v-col cols="6">
        <v-tooltip
          v-if="tooltipDateFrom !== ''"
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              style="position: absolute; margin-top:0px; margin-left:80px; z-index: 9999"
              color="warning"
              v-bind="attrs"
              v-on="on"
            >
              {{ icons.mdiInformation }}
            </v-icon>
          </template>
          <span>{{ tooltipDateFrom }}</span>
        </v-tooltip>
        <v-menu
          v-model="menu1"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date1"
              :label="$t('DateTimePicker.DateFrom')"
              :prepend-icon="icons.mdiCalendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date1"
            color="primary"
            :locale="$i18n.locale"
            :max="maxDateFrom"
            @input="toTransferDateFrom"
          ></v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="6">
        <v-tooltip
          v-if="tooltipDateTo !== ''"
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              style="position: absolute; margin-top:0px; margin-left:80px; z-index: 9999"
              color="warning"
              v-bind="attrs"
              v-on="on"
            >
              {{ icons.mdiInformation }}
            </v-icon>
          </template>
          <span>{{ tooltipDateTo }}</span>
        </v-tooltip>
        <v-menu
          v-model="menu2"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date2"
              :label="$t('DateTimePicker.DateTo')"
              :prepend-icon="icons.mdiCalendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date2"
            color="primary"
            :max="maxDateTo"
            :locale="$i18n.locale"
            @input="toTransferDateTo"
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-radio-group
          v-model="selectedRadio"
          class="mt-0"
          hide-details
          @change="changeRadio"
        >
          <div class="d-flex flex-wrap demo-space-x">
            <v-radio
              :label="$t('DateTimePicker.Today')"
              color="primary"
              value="today"
            ></v-radio>
            <v-radio
              :label="$t('DateTimePicker.Yesterday')"
              color="primary"
              value="yesterday"
            ></v-radio>
            <v-radio
              :label="$t('DateTimePicker.Week')"
              color="primary"
              value="week"
            ></v-radio>
            <v-radio
              :label="$t('DateTimePicker.Month')"
              color="primary"
              value="month"
            ></v-radio>
            <v-radio
              :label="$t('DateTimePicker.AllPeriod')"
              color="primary"
              value="allPeriod"
            ></v-radio>
          </div>
        </v-radio-group>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mdiCalendar, mdiInformation } from '@mdi/js'

export default {
  props: {
    tooltipDateFrom: {
      type: String,
      default: '',
    },
    tooltipDateTo: {
      type: String,
      default: '',
    },
    lockDateFrom: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      date1: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        1,
        new Date().getHours(),
        new Date().getMinutes(),
        new Date().getSeconds(),
      )
        .toISOString()
        .substr(0, 10),
      date2: new Date().toISOString().substr(0, 10),
      menu1: false,
      menu2: false,
      maxDateTo: new Date().toISOString().substr(0, 10),
      maxDateFrom: new Date().toISOString().substr(0, 10),
      icons: {
        mdiCalendar,
        mdiInformation,
      },
      selectedRadio: 'primary',
    }
  },
  mounted() {
    this.initDates()
    this.$eventHub.$on('setDateFrom', date => {
      this.setDateFrom(date)
      this.$forceUpdate()
    })
    this.$eventHub.$on('setDateTo', date => {
      this.setDateTo(date)
      this.$forceUpdate()
    })
  },
  methods: {
    initDates() {
      const date = new Date()
      this.date1 = new Date(
        date.getFullYear(),
        date.getMonth(),
        1,
        date.getHours(),
        date.getMinutes(),
        date.getSeconds(),
      )
        .toISOString()
        .substr(0, 10)
      this.date2 = date.toISOString().substr(0, 10)
    },
    toTransferDateFrom() {
      this.menu1 = false
      this.$emit('getDateFrom', this.date1)
      if (this.date2 < this.date1) {
        this.date2 = this.date1
      }
    },
    toTransferDateTo() {
      this.menu2 = false
      this.$emit('getDateTo', this.date2)
      if (this.date1 > this.date2) {
        this.date1 = this.date2
      }
    },
    setDateFrom(date) {
      this.date1 = date
      this.toTransferDateFrom()
    },
    setDateTo(date) {
      this.date2 = date
      this.toTransferDateTo()
    },
    changeRadio() {
      if (this.selectedRadio === 'today') {
        this.setDateFrom(new Date().toISOString().substr(0, 10))
        if (!this.lockDateFrom) {
          this.setDateTo(new Date().toISOString().substr(0, 10))
        }
      } else if (this.selectedRadio === 'yesterday') {
        const yesterday = new Date()
        yesterday.setDate(yesterday.getDate() - 1)
        this.setDateFrom(yesterday.toISOString().substr(0, 10))
        if (!this.lockDateFrom) {
          this.setDateTo(yesterday.toISOString().substr(0, 10))
        }
      } else if (this.selectedRadio === 'week') {
        const yesterday = new Date()
        yesterday.setDate(yesterday.getDate() - 6)
        this.setDateFrom(yesterday.toISOString().substr(0, 10))
        if (!this.lockDateFrom) {
          this.setDateTo(new Date().toISOString().substr(0, 10))
        }
      } else if (this.selectedRadio === 'month') {
        const yesterday = new Date()
        yesterday.setDate(yesterday.getDate() - 29)
        this.setDateFrom(yesterday.toISOString().substr(0, 10))
        if (!this.lockDateFrom) {
          this.setDateTo(new Date().toISOString().substr(0, 10))
        }
      } else if (this.selectedRadio === 'allPeriod') {
        this.setDateFrom(null)
        if (!this.lockDateFrom) {
          this.setDateTo(null)
        }
      }
    },
  },
}
</script>
<style scoped>
/*.v-radio {*/
/*  align-items: inherit !important;*/
/*  margin-top: -15px !important;*/
/*}*/

/*.v-label {*/
/*  align-items: inherit !important;*/
/*}*/
</style>
